import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ReactElement } from 'react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const API_URL = process.env.REACT_APP_API_URL;

const uploadLink = createUploadLink({
  uri: API_URL,
  credentials: 'include',
  headers: {
    'apollo-require-preflight': 'true',
  },
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, uploadLink as unknown as ApolloLink]),
});

interface ApolloProviderWrapperProps {
  children: ReactElement;
}

const ApolloProviderWrapper = ({ children }: ApolloProviderWrapperProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
