import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import Theme from '@frontend/config/theme';
import Router from '@frontend/router/index';

export default function App() {
  const themeConfig = createTheme(Theme, ptBR);

  return (
    <ThemeProvider theme={themeConfig}>
      <Router />
    </ThemeProvider>
  );
}
