import { gql, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  loginUser: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | null>(null);

export const LOGIN_MUTATION = gql`
  mutation Login($data: AuthenticationInput!) {
    login(authenticationInput: $data) {
      message
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const [login] = useMutation(LOGIN_MUTATION);
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  useEffect(() => {
    const authCookie = Cookies.get('isAuthenticated');
    if (authCookie) {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const loginUser = async (email: string, password: string) => {
    try {
      await login({
        variables: {
          data: { email, password },
        },
      });
      setIsAuthenticated(true);
      Cookies.set('isAuthenticated', 'true');
      return true;
    } catch (error) {
      console.error('Login failed', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await logoutMutation();
      setIsAuthenticated(false);
      Cookies.remove('isAuthenticated');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, loginUser, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
